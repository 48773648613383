import axios from 'axios'
import actions from './actionTypes';
import { apiUrl } from '../helpers';

const getRecordsSuccess = (records) => (dispatch) => {
    dispatch({
        type: actions.GET_RECORDS_SUCCESS,
        records
    });
};
const getRecordsError = () => (dispatch) => {
    dispatch({
        type: actions.GET_RECORDS_ERROR
    });
};
const recordRequest = () => (dispatch) => {
    dispatch({
        type: actions.RECORD_REQUEST
    });
};
export const getRecords = (params) => async (dispatch) => {
    try {
        dispatch(recordRequest());
        const { pageSize, pageNumber, name, sortBy, order } = params;
        let apiEndPoint = `/api/records`;
        if (pageSize) {
            apiEndPoint += '?pageSize=' + pageSize;
        };
        if (pageNumber) {
            apiEndPoint += '&pageNumber=' + pageNumber;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getRecordsSuccess(payload));
    } catch (error) {
        dispatch(getRecordsError());
    }
};
export const downloadRecord = (params) => async () => {
    try {
        const { name, sortBy, order } = params;
        let apiEndPoint = `/api/export/records?`;

        if (sortBy && order) {
            apiEndPoint += 'sortBy=' + sortBy + '&order=' + order;
        };
        if (name) {
            apiEndPoint += '&name=' + name;
        };

        const { data } = await axios.get(apiUrl(apiEndPoint), { responseType: 'blob' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Records.xlsx'); // Set the file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log("🚀 ~ downloadRecord ~ error:", error);
    }
};