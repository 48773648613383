import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import Cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
const { REACT_APP_APPLICATION_ID, REACT_APP_CLIENT_TOKEN, REACT_APP_SERVICE } = process.env;

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      Cookies.remove('adminToken');
      localStorage.clear('adminState');
      window.location.href = '/login';
      return err;
    }
    return Promise.reject(err);
  }
);
datadogRum.init({
  applicationId: REACT_APP_APPLICATION_ID,
  clientToken: REACT_APP_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: REACT_APP_SERVICE,
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});
datadogRum.startSessionReplayRecording();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
  // document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
